@function toRem($a) {
    @return ($a/37.5) + rem;
}

.enterprise-list {
    font-size: toRem(14);
    color: #333;
    overflow-y: auto;
    height: 100%;
    .enterprise-search {
        display: flex;
        width: toRem(694/2);
        margin: 0 auto;
        margin-top: toRem(15);
        height: toRem(41);
        background-color: #fff;
        border-radius: toRem(20);
        align-items: center;
        padding: 0 toRem(7) 0 toRem(22);
        box-sizing: border-box;
        margin-bottom: toRem(11);
        .screen {
            font-size: toRem(13);
            color: #ff1607;
            margin-right: toRem(22);
        }
        .put {
            display: flex;
            align-items: center;
            flex: 1;
            .adm-image {
                width: toRem(13.5);
                height: toRem(13);
                margin-right: toRem(5);
            }
            .adm-input {
                flex: 1;
                input {
                    font-size: toRem(13);
                }
            }
        }
        .search-btn {
            height: toRem(30);
            width: toRem(60);
            background-color: #ff1607;
            border-radius: toRem(15);
            color: #fff;
            font-size: toRem(12);
            text-align: center;
            line-height: toRem(30);
        }
        .reset{
            margin:0 toRem(5);
            font-size: toRem(12);
        }
    }
    .enterprise-banner {
        padding: 0 toRem(14);
        img {
            height: toRem(150);
        }
    }
    .empty{
        text-align: center;
        color: #999;
        font-size: toRem(13);
        margin-top:toRem(50);
        img{
            width: toRem(250);
            height: toRem(250);
        }
    }
    .list-category {
        background-color: #fff;
        border-radius: toRem(14) toRem(14) 0 0;
        margin-top: toRem(8);
        .category {
            display: flex;
            overflow-x: scroll;
            align-items: center;
            height: toRem(40);
            align-items: center;
            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
            div {
                flex: none;
                margin-right: toRem(26);
                color: #999;
                &:first-child {
                    margin-left: toRem(26);
                }
            }
            .active {
                font-size: toRem(14);
                font-weight: bold;
                color: #ff1607;
            }
        }
       
        .list {
            padding: 0 toRem(14);
            .item {
                background: #f5f5f5;
                margin-bottom: toRem(10);
                border-radius: toRem(10);
                padding: toRem(15) 0 toRem(10) 0;
                .enterprise-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: toRem(10);
                    padding: 0 toRem(14);
                    .logo-name {
                        flex:1;
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(44);
                            height: toRem(44);
                            border-radius: 50%;
                            margin-right: toRem(10);
                            object-fit: cover;
                        }
                        .name {
                            width: toRem(200);
                            p:nth-child(2) {
                                font-size: toRem(13);
                                color: #999;
                                margin-top: toRem(5);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .go {
                        font-size: toRem(13);
                        color: #fff;
                        line-height: toRem(23);
                        text-align: center;
                        width: toRem(60);
                        background-color: #ff1607;
                        border-radius: toRem(14);
                    }
                }
                .banner {
                    margin: toRem(10) 0;
                    padding: 0 toRem(14);
                    img {
                        height: toRem(125);
                        width: 100%;
                        object-fit: cover;
                        border-radius: toRem(5);
                    }
                }
                .goods {
                    display: flex;
                    overflow-x: scroll;
                    margin-bottom: toRem(10);
                    &::-webkit-scrollbar {
                        width: 0px;
                        height: 0px;
                    }
                    .goods-item {
                        flex: none;
                        width: toRem(90);
                        height: toRem(90);
                        border-radius: toRem(10);
                        margin-right: toRem(15/2);
                        background-size: 100% 100%;
                        position: relative;
                        &:first-child {
                            margin-left: toRem(14);
                        }
                        div {
                            padding: toRem(3.5) toRem(6.5);
                            background: rgba(0, 0, 0, 0.4);
                            color: #fff;
                            font-size: toRem(12);
                            position: absolute;
                            bottom: toRem(6);
                            right: toRem(6);
                            border-radius: toRem(8);
                        }
                    }
                }
                .type {
                    padding: 0 toRem(14);
                    display: flex;
                    justify-content: space-between;
                    font-size: toRem(12);
                    color: #ccc;
                    div {
                        display: flex;
                        align-items: center;
                    }
                    img {
                        width: toRem(9);
                        height: toRem(11);
                        margin-right: toRem(3.5);
                    }
                }
            }
        }
    }
}

@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content{
    font-size: toRem(14);
    background-size: 100% 100%;
    .pay_info{
        height: toRem(691/2);
        background-size: 100% 100%;
        overflow: hidden;
        div{
            text-align: center;
            color: #fff;
        }
        div:nth-child(1){
            text-align: center;
            margin-top:toRem(25);
            margin-bottom: toRem(6);
            img{
                width: toRem(37);
                height: toRem(37);
                border-radius: 50%;
            }
        }
        div:nth-child(2){
            margin-bottom: toRem(6);
        }
        div:nth-child(3){
            font-size: toRem(34);
            font-weight: bold;
            margin-bottom: toRem(40);
        }
        div:nth-child(4),div:nth-child(5),div:nth-child(6),div:nth-child(7){
            display: flex;
            justify-content: space-between;
            padding:0 toRem(28);
            margin-bottom: toRem(6);
        }
    }
    .banner{
        width: toRem(694/2);
        background:#fff;
        box-sizing: border-box;
        padding:toRem(14);
        margin:0 auto;
        position: relative;
        top:toRem(-50);
        border-radius: toRem(12);
        .title{
            margin-bottom: toRem(12);
        }
    }
}